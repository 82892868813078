import React, { createContext, ReactNode, useContext, useEffect, useState } from "react";

const BreakpointsContext = createContext<Breakpoints | undefined>(undefined)

type Breakpoints = {
    sm: boolean;
}

export function BreakpointsProvider({children}: {children: ReactNode }) {
    const [sm, setSm] = useState(true);
    useEffect(() => {
        const mediaQuery = window.matchMedia("(max-width: 768px)");
        setSm(mediaQuery.matches) // init

        const handler = (e: MediaQueryListEvent) => {
            setSm(e.matches);
        }
        mediaQuery.addEventListener('change', handler);

        return () => {
            mediaQuery.removeEventListener('change', handler);
        }
    }, []);
      
    const value = {
        sm,
    };

    return <BreakpointsContext.Provider value={value}>{children}</BreakpointsContext.Provider>
}

export function useBreakpoints() {
    const context = useContext(BreakpointsContext)
    if (context === undefined) {
      throw new Error('useBreakpoints must be used within a BreakpointsProvider')
    }
    return context
}
