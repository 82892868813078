import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";

type AuthStatus = "logged-out" | "logged-in" | "error" | "loading";

interface Auth {
    login: (id: string) => void;
    logout: () => void;
    status: AuthStatus;
    accountName: string | undefined;
    resetError: () => void;
}

const LOCAL_STORAGE_TOKEN_KEY = "accountId";

const AuthContext = createContext<Auth | undefined>(undefined)

export function AuthProvider({ children }: { children: ReactNode }) {
    const [status, setStatus] = useState<AuthStatus>("logged-out");
    const [accountName, setAccountName] = useState<string | undefined>();

    const login = useCallback(async (id: string) => {
        setStatus("loading");
        try {
            const resp = await fetch('/api/auth', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id })
            })
            if (resp.ok) {
                setStatus("logged-in");
                const body = await resp.json();
                setAccountName(body.name);
                localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, id);
            } else {
                console.log("Got error in response", resp.status)
                setStatus("error");
            }
        } catch (err: any) {
            setStatus("error");
            console.error(err);
        }
    }, []);

    const logout = useCallback(() => {
        setStatus("logged-out");
        localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
        setAccountName(undefined);
    }, []);

    useEffect(() => {
        const prevToken = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
        if (prevToken) {
            login(prevToken);
        }
    }, [login]);

    const resetError = () => {
        if (status === "error") {
            setStatus("logged-out");
        }
    };

    return <AuthContext.Provider value={{ status, accountName, login, logout, resetError }}>{children}</AuthContext.Provider>
}

export function useAuth() {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider')
    }
    return context
}
