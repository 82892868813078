import { BreakpointsProvider } from '../hooks/useBreakpoints';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/main.scss'
import { AuthProvider } from '../hooks/useAuth';
import { createTheme, NextUIProvider } from "@nextui-org/react"
import type { AppProps } from 'next/app'

const theme = createTheme({
  type: 'light',
  theme: {
    radii: {
      xs: '0px',
      sm: '0px',
      md: '0px',
      base: '0px',
      lg: '0px', // preferred value by NextUI components
      xl: '0px',
      squared: '0px',
      rounded: '0px',
      pill: '0px'
    }
  }
});

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <NextUIProvider theme={theme}>
      <BreakpointsProvider>
        <AuthProvider>
          <Component {...pageProps} />
        </AuthProvider>
      </BreakpointsProvider>
    </NextUIProvider>
  );
}

export default MyApp;
